import React from "react";

// Dashboard
import SalesMonitoring from "../dashboard/SalesMonitoring";
import ProductList from "../products/All";
import ServiceList from "../services/All";
import PackagesList from "../packages/All";


const protectedRoutes = [
  { path: "/dashboard", element: <SalesMonitoring /> },
  { path: "/products/all", element: <ProductList /> },
  { path: "/services/all", element: <ServiceList /> },
  { path: "/packages/all", element: <PackagesList /> },
];


export default protectedRoutes;