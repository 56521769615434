import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Sidebar from "../layouts/Sidebar";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import axios from "axios";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
// or use moment if you prefer

export default function Products() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [data, setData] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [fetchText, setFetchText] = useState("Fetch Products");

  // const fetchProducts = async () => {
  //   setBtnDisabled(true);
  //   setFetchText("Fetching Products");
  //   try {
  //     let response = await axios.post(
  //       "https://salonistapp.com/api/products",
  //       {
  //         domainId: sessionStorage.getItem("domainId"),
  //       }
  //     );

  //     let responseData = response.data;
  //     if (responseData.success === 200) {
  //       setBtnDisabled(false);
  //       setFetchText("Fetch Products");
  //       toastr.success(responseData.message);
  //       // Fetch all products after the successful response
  //       allProduct();
  //     }
  //   } catch (error) {
  //     setBtnDisabled(false);
  //     setFetchText("Fetch Products");
  //     toastr.error(error.response?.data?.message || "Error fetching products");
  //   }
  // };

  const allProduct = async () => {
    try {
      const response = await axios.get("https://salonistapp.com/api/product", {
        params: {
          domain_id: sessionStorage.getItem("domainId"),
        },
      });

      if (response.data.success === 200) {
        setData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching all products:", error);
    }
  };
  console.log("product response", data);
  // You can optionally call fetchProducts or allProduct on component mount
  useEffect(() => {
    allProduct();
  }, []);
  return (
    <>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Products / All
              </li>
            </ol>
            <h4 className="main-title mb-0">Products</h4>
          </div>
        </div>

        <div className="main-docs mr-1">
          <Card>
            <Card.Body>
              <div className="table-responsive">
                <Table className="mb-0 table ">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Brand</th>
                      <th scope="col">Image</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Product Category</th>
                      <th scope="col">Type</th>
                      <th scope="col">Full Price</th>
                      <th scope="col">Cost Price</th>
                      <th scope="col">Sale Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item) => (
                        <tr key={item.product_id}>
                          <td>{item.product_id}</td>
                          <td>{item.name}</td>
                          <td>{item.brand}</td>
                          <td>
                            {/* Assuming 'img' contains the URL of the image */}
                            <img
                              src={item.img}
                              alt={item.name}
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                              }}
                            />
                          </td>
                          <td>{item.qty ?? 0}</td>
                          <td>{item.product_type}</td>
                          <td>{item.type}</td>
                          <td>{item.full_price ?? "-"}</td>
                          <td>{item.cost_price}</td>
                          <td>{item.sale_price}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <Footer />
    </>
  );
}
