import React, { useState, useEffect } from "react";
import { Card, Table } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Sidebar from "../layouts/Sidebar";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import axios from "axios";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

export default function Packages() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [data, setData] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [fetchText, setFetchText] = useState("Fetch Products");

  // const fetchProducts = async () => {
  //   setBtnDisabled(true);
  //   setFetchText("Fetching Products");
  //   try {
  //     let response = await axios.post(
  //       "https://salonistapp.com/api/packages",
  //       {
  //         domainId: sessionStorage.getItem("domainId"),
  //       }
  //     );

  //     let responseData = response.data;
  //     if (responseData.success === 200) {
  //       setBtnDisabled(false);
  //       setFetchText("Fetch Products");
  //       toastr.success(responseData.message);
  //       // Fetch all products after the successful response
  //       allProduct();
  //     }
  //   } catch (error) {
  //     setBtnDisabled(false);
  //     setFetchText("Fetch Products");
  //     toastr.error(error.response?.data?.message || "Error fetching products");
  //   }
  // };

  const allPackages = async () => {
    try {
      const response = await axios.get("https://salonistapp.com/api/packages", {
        params: {
          domain_id: sessionStorage.getItem("domainId"),
        },
      });

      if (response.data.success === 200) {
        setData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching all products:", error);
    }
  };
  console.log("packages response", data);
  // You can optionally call fetchProducts or allProduct on component mount
  useEffect(() => {
    allPackages();
  }, []);

  // const PackagesData = [
  //   {
  //     id: 1,
  //     name: "Starter Pack",
  //     brand: "Starter Inc.",
  //     image: "https://via.placeholder.com/50", // Replace with actual URLs
  //     quantity: 10,
  //     category: "Basic",
  //     type: "Subscription",
  //     fullPrice: "$100",
  //     costPrice: "$80",
  //     salePrice: "$90",
  //   },
  //   {
  //     id: 2,
  //     name: "Pro Pack",
  //     brand: "Pro Solutions",
  //     image: "https://via.placeholder.com/50", // Replace with actual URLs
  //     quantity: 20,
  //     category: "Advanced",
  //     type: "Subscription",
  //     fullPrice: "$200",
  //     costPrice: "$150",
  //     salePrice: "$180",
  //   },
  //   {
  //     id: 3,
  //     name: "Enterprise Pack",
  //     brand: "Enterprise Co.",
  //     image: "https://via.placeholder.com/50", // Replace with actual URLs
  //     quantity: 5,
  //     category: "Premium",
  //     type: "One-time",
  //     fullPrice: "$500",
  //     costPrice: "$400",
  //     salePrice: "$450",
  //   },
  // ];

  return (
    <>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        {/* Breadcrumb and Title */}
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Packages / All
              </li>
            </ol>
            <h4 className="main-title mb-0">Packages</h4>
          </div>
        </div>

        {/* Packages Table */}
        <div className="main-docs mr-1">
          <Card>
            <Card.Body>
              <div className="table-responsive">
                <Table className="mb-0 table">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Brand</th>
                      <th scope="col">Image</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Package Category</th>
                      <th scope="col">Type</th>
                      <th scope="col">Full Price</th>
                      <th scope="col">Cost Price</th>
                      <th scope="col">Sale Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item) => (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.name}</td>
                          <td>{item.brand}</td>
                          <td>
                            <img
                              src={item.image}
                              alt={item.name}
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                              }}
                            />
                          </td>
                          <td>{item.quantity}</td>
                          <td>{item.category}</td>
                          <td>{item.type}</td>
                          <td>{item.fullPrice}</td>
                          <td>{item.costPrice}</td>
                          <td>{item.salePrice}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <Footer />
    </>
  );
}
