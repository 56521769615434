import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Sidebar from "../layouts/Sidebar";

export default function SalesMonitoring() {
 
  

  return (
    <React.Fragment>
      <Sidebar/>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
            </ol>
            <h4 className="main-title mb-0">Welcome to Dashboard</h4>
          </div>
        </div>

      </div>
        <Footer />
    </React.Fragment>
  )
}