import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Sidebar from "../layouts/Sidebar";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import axios from "axios";

export default function Services() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [data, setData] = useState([]);

  const allService = async () => {
    console.log("Fetching services...");
    const domainId = sessionStorage.getItem("domainId");
    console.log("Domain ID:", domainId);

    if (!domainId) {
      console.error("Error: domainId is null or undefined.");
      return;
    }

    try {
      const response = await axios.get("https://salonistapp.com/api/services", {
        params: { domain_id: domainId },
      });
      console.log("Service API Response:", response);

      if (response.data.success === 200) {
        setData(response.data.data);
        console.log("Fetched Services Data:", response.data.data);
      } else {
        console.error("Unexpected success code:", response.data.success);
      }
    } catch (error) {
      console.error("Error fetching services:", error.message);
    }
  };

  useEffect(() => {
    allService();
  }, []);

  return (
    <>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Services / All
              </li>
            </ol>
            <h4 className="main-title mb-0">Services</h4>
          </div>
        </div>

        <div className="main-docs mr-1">
          <Card>
            <Card.Body>
              <div className="table-responsive">
                <Table className="mb-0 table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Brand</th>
                      <th>Image</th>
                      <th>Quantity</th>
                      <th>Service Category</th>
                      <th>Type</th>
                      <th>Full Price</th>
                      <th>Cost Price</th>
                      <th>Sale Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item, index) => (
                        <tr key={index}>
                          <td>{item.id}</td>
                          <td>{item.name}</td>
                          <td>{item.brand || "N/A"}</td>
                          <td>
                            <img
                              src={
                                item.image || "https://via.placeholder.com/50"
                              }
                              alt={item.name}
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                              }}
                            />
                          </td>
                          <td>{item.quantity || 0}</td>
                          <td>{item.category || "N/A"}</td>
                          <td>{item.type || "N/A"}</td>
                          <td>{item.full_price || "-"}</td>
                          <td>{item.cost_price || "-"}</td>
                          <td>{item.sale_price || "-"}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <Footer />
    </>
  );
}
