import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import Signin from "../pages/Signin";

function PrivateRoute(){
    const domainId = sessionStorage.getItem('domainId');

    if(!domainId){
        return <Signin />
    }
    return <Outlet />
}

export default  PrivateRoute ;